.tools {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  justify-content: space-between;
  margin: 2rem;
  margin-top: 1rem;
  padding: 2rem;
  border: 2rem;
  border-radius: 2rem;
  background: #ffffff; /* White background */
  
}

.heading{
  /*increase the size of text */
  font-size: 1.5rem;
 
}
.tool-item {
  flex: 1 1 calc(50% - 4rem); /* Two boxes in one row */
  display: flex;
  flex-direction:column;
  margin: 2rem;
  padding: 2rem; /* Adjusted padding */
  border-radius: 1rem;
  border-style:outset;
  border-width: 1px;
  border-color: #535353;
  background: #f1f3f4; /* Blue background */
  position: relative; /* Added relative positioning */
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2); /* Box shadow for 3D effect */
  transition: box-shadow 0.2s ease-in, transform 0.2s ease-in, background-color;
}

.tool-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Increase the scale on hover to create a zoom effect */
  transform: scale(1.005);
  background-color: #eff0f0;
}

.line-divider {
  height: 2px;
  width: 100%;
  background: #535353; /* Blue color for the line */
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.logo-and-text-container {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items horizontally */
}

.toolsText {
  margin: 0rem ;
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items:center; 
 justify-content: center;
  color: black;
}

.toolsText>h2:hover {
  color:blueviolet;
  cursor: pointer;
}

.tool-item>p {
  font-size: 0.87rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  margin-top: 0.5rem; 
  color: black;
}

.toolsText>h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: black;
}

.logo1,
.logo2,
.logo3,
.logo4 {
  object-fit:contain;
  height: 4rem;
  width: 4rem;
 
}

.circle-icon {
  width: 50px; /* Set the desired width */
  height: 50px; /* Set the desired height */
  border-radius: 50%; /* Make it a circle */
  overflow: hidden; /* Ensure content doesn't extend beyond the circle */
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
}

.circle-icon img {
  width: 100%; /* Make the image fill the circle */
  height: 100%; /* Make the image fill the circle */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}
