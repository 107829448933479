.NavBar {
   margin-top: 0rem;
   flex: auto;
   flex-direction: column;
   align-items: center;
 
}

.heading {
    background: linear-gradient(45deg, #3498db, #2b2caa);/*#2b2caa ; /* Slightly darker blue background */
    color: white; /* White text */
    font-size: 18px; /* Change the font size to 18 pixels */
    padding: 0.3rem;
    font-weight: 400;
}


.user {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 3px;
}

.welcome-message {
    margin: 1rem; 
    color: black;
    font-size: 1.2rem;
    font-weight:600;
    margin-top: 2.5rem;
    margin-left: 2%;

}
.logout {
    align-items: end;
    justify-content: end;
    flex: auto;
    flex-direction: row;
    margin-left: 80%;

  }

.logoutButton {
  background-color: rgb(74, 170, 244);
  color: white;
  padding: 11px 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;

}
