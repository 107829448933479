body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.loginnavbar {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background-color: white;
  padding: 10px;
  font-size: large;
}

.login-second-navbar {
  background-color: rgb(0, 0, 136);
  color: white;
  padding: 20px;
}

.main-content {
  display: flex;
  flex: 1;
}

.logo img {
  max-height: 40px;
  /* Adjust the size of the logo */
}

.brand {
  font-weight: bold;
}

.login-form {
  margin-top: 70px;
  margin-left: 5%;
  max-width: 50%;
}

form {
 margin-top: 80px;
  display: inline-block;
  text-align: left;
  width: 500px;
}

.logintext{
 color: black;
 text-align: left;
 margin-left: 56%;
 margin-top: 30%;
}
.footer{
 color: rgb(152, 152, 152);
 text-align: center;
 margin-left: 30%;
 margin-top: 2%;
 font-size: 0.70rem;
}
label {
  display: block;
  margin-bottom: 5px;
  /* Adjust the spacing between label and input */
  margin-left: 5%;
  color: black;
}

input {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 500px;
  padding: 5px;
  display: block;
  /* Set input fields as block elements */
  margin: 10px 0;
  /* Adjust the spacing between input and next label */
}

.keycloak-button {
  background-color: rgb(74, 170, 244);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 350px;
  margin-left: 15%;
  margin-top: 1px;
}
.keycloak-button{
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 5%;
  margin-left: 15%;
  font-size: 1rem;
}
.background-image {
  flex:content;
  overflow:hidden;
  
}

.background-image img {
  margin-left: 10%;
  object-fit: contain;
  width: 80%;
  height: auto;
}

#first-nav {
  border-right: 2px solid #0e0d0ddf;
  /* Change the width as needed */
  height: 20vb;
  /* Adjust the height as needed */
  margin-top: 5%;
  margin-left: 10px;
  margin-right: 10px;
}

.brand h4 {
  margin-block-end: auto;
  margin-top: 20px;
  margin-bottom: 7px;
  font-weight: 900;
  color: rgb(0, 0, 136);
}

.note{
  color: rgb(152, 152, 152);
 text-align: center;
 margin-left: 30%;
 margin-top: 2%;
 font-size: 0.70rem;
}
